<template>
  <CriaLeg />
</template>

<script>
import CriaLeg from "@/components/legislacao/CriaLegislacao.vue";

export default {
  components: {
    CriaLeg
  }
};
</script>
